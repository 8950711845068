@import "./src/theme/components/swiper/articleImagesSlider";
@import "./src/theme/components/swiper/storySlides";

swiper#introSlider {
    .swiper-pagination-bullets {
        bottom: 100px;
        .swiper-pagination-bullet {
            background: var(--ion-color-white);
        }
    }
}

.swiper {
    
    .swiper-pagination {
        left: 0px;
        right: 0px;
    
        @media screen and (prefers-color-scheme: dark) {
            --bullet-background: var(--ion-color-dark);
        }
    
    }

    .swiper-button-prev {
        zoom: 0.75;
        filter: grayscale(100%);
        left: -45px !important;
    }
    
    .swiper-button-next {
        zoom: 0.75;
        filter: grayscale(100%);
        right: -45px !important;
    }
    
}

pipeline-post-suggestions-card {
    margin-left: -15px;
    margin-right: -15px;
    float: left;
    width: calc(100% + 30px);

    ion-card {
        margin: 0px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

}