ion-menu {
  border-left: none;
  --border: 0px !important;
  --padding-top: var(--ion-safe-area-top);
  padding-top: var(--ion-safe-area-top);

  .hiItem {
    margin-top: 7.5vh !important;
  }
  
  ion-footer {
    --background: var(--pipeline-sidebar-background-color, transparent);
    background: var(--pipeline-sidebar-background-color, transparent);
    
    ion-toolbar {
      --background: transparent;
      background: transparent;
      
      
      .version {
        p, a {
          --color: var(--pipeline-sidebar-background-color-contrast, #fff);
          color: var(--pipeline-sidebar-background-color-contrast, #fff);
        }
      }

      ion-list {
        ion-menu-toggle {
          ion-item {
            --min-height: 50px;
            --height: 50px;
            height: 50px;
            ion-label {
              h3 {
                margin-bottom: -3px !important;
              }
            }
          }
        }
      }
    }
  }
}