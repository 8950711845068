.post-page {
  overflow: hidden;
  --border-radius: 20px;
  position: relative;
  z-index: 10;
  background: var(--ion-color-light) !important;
  color: var(--ion-color-dark) !important;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;

  @media screen and (max-width: 768px) {
    width: 100vw;
  }

  &.post-page-size1 {
    div,
    p {
      font-size: 16px !important;
    }
  }

  &.post-page-size2 {
    div,
    p {
      font-size: 18px !important;
    }
  }

  &.post-page-size3 {
    div,
    p {
      font-size: 20px !important;
    }
  }

  &.videoMode {
    .swiper-slide,
    .post,
    .ion-padding {
      padding-bottom: 0px !important;
      padding-top: 0px !important;

      video {
        margin-bottom: 15px !important;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        width: 100vw;
      }
    }
  }

  &.tts {
    div,
    p {
      font-size: 28px !important;
    }
  }

  .ion-padding {
    --padding-top: 0px;
    line-height: 1.5em;
    font-weight: 500;
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;

    a {
      color: var(--ion-color-dark) !important;
      font-weight: 900;
    }
  }

  &.invert {
    background: var(--ion-color-dark) !important;
    color: var(--ion-color-light) !important;
    .ion-padding {
      a {
        color: var(--ion-color-light) !important;
      }
    }
  }
}

ion-content {
  .post-page {
    h2,
    h3,
    h4,
    b {
      font-weight: 700;
    }

    iframe {
      max-width: calc(100% + 60px) !important;
      width: calc(100% + 60px) !important;
      height: 57.5vw !important;
      margin-left: -30px !important;
      margin-right: -30px !important;
    }

    figure {
      margin: 0px !important;
    }

    video::-internal-media-controls-download-button {
      display: none;
    }

    video::-webkit-media-controls-enclosure {
      overflow: hidden;
    }

    audio {
      float: left;
      width: 100%;
      margin-bottom: 15px;
    }

    table,
    video,
    img {
      width: 100vw;
      float: left;
      margin-bottom: 15px;
      max-width: 100vw;
      margin-left: -30px;

      thead {
        background: rgba(50, 50, 50, 0.2);
      }

      thead tr th,
      tbody tr td {
        padding: 0px 5px;
      }

      tbody tr:nth-of-type(even) td {
        background: rgba(50, 50, 50, 0.1);
      }

      @media screen and (min-width: 769px) {
        width: calc(100% + 60px);
        max-width: calc(100% + 60px);
      }

      figcaption {
        font-size: 12px;
      }

      &[src*="loading.gif"],
      &[src*="data:"] {
        display: none !important;
      }
    }

    img {
      height: auto;
    }

    input,
    textarea,
    form,
    [data-borlabs-cookie-unblock],
    [data-borlabs-cookie-type],
    div[data-services],
    div:last-child > ul:last-child,
    a[aria-label*="teilen"],
    a[aria-label*="Pinterest pinnen"],
    a[aria-label*="E-Mail versenden"],
    span[itemprop="reviewBody"] > div:first-child > ul {
      display: none !important;
    }
    
  }

}
