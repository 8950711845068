.calendarBtn,
.eventsListBtn,
.orderByBtn {
    ion-icon {
        font-size: 25px;
    }
}

.calendarBtn,
.eventsListBtn,
.orderByBtn {
    position: absolute;
    right: -10px;
    top: 5px;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}