.headlineToolbar {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;

  
  ion-thumbnail {
    width: 100%;
    height: calc(100% + 30px);

    ion-img {
      width: 100%;
      height: 100%;
    }
  }

  .headlineToolbarContainer {
    position: absolute;
    bottom: 50px;
    width: 100%;
  }

  > div {
    float: left;
    width: 100%;
    min-height: 27.5vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba(0, 0, 0, 0.25);
    }
  }

  h1 {
    position: relative;
    bottom: 0px;
    z-index: 12;
    padding-top: 7.5%;
    padding-bottom: 0px;
    color: #fff !important;
  }

  &.videoMode {
    h1 {
      padding-top: calc(var(--ion-safe-area-top) + 30px);
      padding-bottom: 5px;
    }
  }
}

.headlineToolbar {
  margin-top: -20px;
  height: calc(35vh + 50px);
  margin-bottom: -20px;
  //padding-top: 20vh;
  padding-bottom: 35px;
  position: sticky;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: calc(100% + 1px);
    background: var(--ion-color-black);
    opacity: 0.4;
    z-index: 0;
  }

  &.videoMode::before {
    opacity: 0.8;
  }

  h1 {
    font-weight: 800;
    font-size: 24px;
    margin: 10px;
    padding-left: 15px;
    padding-right: 0px;
    width: calc(100% - 50px);
    text-overflow: unset;
    white-space: unset;
  }

  ion-header {
    top: 3.5vh;
    ion-title {
      font-weight: 800;
      font-size: 25px;
      margin: 10px;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 50px);
      text-overflow: unset;
      white-space: unset;
    }
  }

  .hostItem {
    --overflow: visible;
    overflow: visible;
    --background: transparent !important;
    background: transparent !important;

    &.hasAvatar {
      ion-label {
        padding-left: 60px;
      }
    }
    
    .avatarImg {
      border-radius: 50px;
      position: absolute;
      height: 45px;
      width: 45px;
      max-height: none;
      z-index: 2;
      background: #fff;
      --background: #fff;
    }
  }
}
