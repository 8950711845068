.ck.ck-toolbar,
.ck.ck-editor__main {
  background: transparent !important;
  border-width: 0px !important;
  color: var(--ion-color-dark) !important;

  > .ck-editor__editable {
    background: transparent !important;
    border-width: 0px !important;
    color: var(--ion-color-dark) !important;
  }

  .ck.ck-button,
  .ck.ck-icon {
    color: var(--ion-color-dark) !important;
    fill: var(--ion-color-dark) !important;
  }
  
}

.ck-editor,
.editor {
  max-height: 75vh;

  @media screen and (min-width: 1024px) {
    max-width: calc(75vw - 270px);
  }

}

.editor {

  @media screen and (min-width: 1024px) {
    margin: 10px;
    margin-left: 290px;
  }

  .ck-editor__editable,
  .ql-editor {
    min-height: 35vh;
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
    max-height: 80vh;
    overflow-y: auto;

    @media screen and (min-width: 1024px) and (orientation: landscape) {
      min-height: 65vh;
    }

    h1 {
      font-size: 36px;
      font-weight: 800;
    }

    h2 {
      font-size: 26px;
      font-weight: 800;
    }

    p,
    ul,
    ol,
    table {
      font-size: 18px;
      font-weight: 500;
    }

    b,
    strong {
      font-weight: 900;
    }
  }

}

.ql-clean,
.ql-link {
  display: none !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-width: 0px !important;

  button {
    height: 28px;
    width: 28px;
  }
}

.ql-container img {
  height: auto !important;
}