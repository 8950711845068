.peopleGrid {
  ion-row {
    ion-col {
      ion-card {
        margin: 0px;
        text-align: center;
        padding: 5px 0px;
        border-radius: 15px;
        min-width: 115px;
        margin-bottom: 0px;

        ion-avatar {
          margin: auto;
          display: block;
          --background: #fff;
          background: #fff;
        }

        ion-label {
          margin-top: 5px;
          margin-bottom: 5px;
          float: left;
          width: 100%;
          overflow: hidden;
          -moz-hyphens: auto;
          hyphens: auto;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

      }
    }
  }
}
