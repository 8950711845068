.curve {
    position: absolute;
    left: 0px;
    width: 100vw;
    height: auto;
    z-index: 9;
    object-fit: contain;

    &.forgot-password-curve {
        path {
            fill: var(--pipeline-forgot-password-web-left-area-background-color);
        }        
    }

    &.login-curve {
        path {
            fill: var(--pipeline-login-web-left-area-background-color);
        }        
    }

    &.register-curve {
        path {
            fill: var(--pipeline-register-web-left-area-background-color);
        }        
    }

    &.curve-pink {
        path {
            fill: #e2d8ff;
        }
    }

    &.curve-vertical {
        width: 10vw;
        height: 100vh;
        top: 0px;
    }

    &.curve-middle {
        right: -6.5vh;
        left: 0px;
        margin: auto;
        display: block;
        position: fixed;
    }

    &.curve-bottom {
        bottom: -2px;
        &.curve-rotated {
            bottom: -3.8vw;
        }
    }

    &.curve-top {
        top: -2px;
        &.curve-rotated {
            top: -3.8vw;
        }
    }
    
    &.curve-rotated {
        transform: rotate(180deg);
    }

}