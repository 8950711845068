swiper#articleImagesSlider {
    overflow: hidden;
    display: block !important;
    min-height: 40vh;
    max-height: 70vh;

    @media screen and (max-width: 768px) {
        height: 100vw;
    }

    .swiper-slide {
        overflow: hidden;
        display: inline-grid !important;
        
        @media screen and (min-width: 1024px) {
            max-height: 50vh;
        }

        img {
            max-width: auto;
            margin: auto;
            height: 100%;
            max-height: 40vh;
            vertical-align: middle;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0, 0.25);
            z-index: 2;
        }
    }
}