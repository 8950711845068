ion-modal {
  @media screen and (min-width: 1024px) {
    &.postModal,
    &.previewPostSocialSharingImagesModal,
    &.postPreviewModal {
      .headlineToolbar {
        position: relative !important;
        max-width: 1100px;
        position: relative;
        left: 0px;
        right: 0px;
        margin: auto;
        display: block;
        min-height: 35vh !important;

        .headlineToolbarContainer {
          max-width: calc(1024px - 20px);
          left: 0px;
          right: 0px;
          margin: auto;
          display: block;

          h1 {
            padding-top: 0px;
            padding-bottom: 30px;
          }
        }
      }
    }

    &.editPostSettingsModal {
      --height: calc(
        100% - var(--ion-safe-area-top) + var(--ion-safe-area-bottom)
      ) !important;
      --width: 270px;
      width: 270px;
      left: 0px;
      right: auto;
      top: 0px;
      height: calc(
        100% - (170px + var(--ion-safe-area-top) + var(--ion-safe-area-bottom))
      ) !important;
      bottom: 44px;
      top: auto;

      .modal-wrapper {
        --width: 100% !important;
        width: 100% !important;
      }
    }
  }

  &#ion-overlay-3 {
    ion-content {
      ion-toolbar {
        > ion-buttons {
          margin-top: 0px;
        }
        > ion-title {
          margin-top: 0px;
        }
      }
    }
  }

  &.createAdModal,
  &.eventDetailModal,
  &.weclappConnectionModal,
  &.defaultModal,
  &.chooseActionSheetPage,
  &.loomaModal,
  &.basketModal,
  &.paymentsModal,
  &.fullscreenModal,
  &.postPreviewModal,
  &.previewPostSocialSharingImagesModal,
  &.searchModal,
  &.createAdModal,
  &.loginModal,
  &.createTargetGroupModal,
  &.connectBlogPage,
  &.connectShopPage,
  &.editShopSourceModal,
  &.editSourceModal,
  &.pageModal,
  &.loomaModal,
  &.postModal,
  &.profileModal,
  &.writePostModal,
  &.storyModal,
  &.editWebsiteModal,
  &.createWebsiteModal,
  &.productModal {
    --width: 100vw;
    --min-height: calc(100vh - var(--ion-safe-area-top));
    min-height: calc(100vh - var(--ion-safe-area-top));
  }

  &.avatarModal,
  &.pageModal,
  &.loomaModal,
  &.createWordListEntryModal,
  &.cookiesModal,
  &.defaultModal,
  &.basketModal,
  &.checkoutModal,
  &.bottomModal,
  &.editSourceModal,
  &.paymentsModal,
  &.blogAdminFiltersModal,
  &.sharingModal,
  &.changeAccountModal,
  &.orderByModal,
  &.shopAdminFiltersModal,
  &.createRegionalSourceModal,
  &.collectionPickerModal,
  &.collectionModal,
  &.configureCollectionModal,
  &.pickIconModal,
  &.loginModal,
  &.regionSetupModal,
  &.verifiedInfoModal {
    [role="dialog"] {
      position: absolute;
      --height: 40vh;
      height: 40vh;
      --min-height: 40vh;
      min-height: 40vh;
      --max-height: 40vh;
      max-height: 40vh;
      bottom: 0px;
      top: auto;
      --max-width: 100vw;
      max-width: 100vw;
    }

    &.cookiesModal {
      --height: 350px;
      --max-height: 50vh;
    }

    &.createWordListEntryModal,
    &.chooseActionSheetPage,
    &.avatarModal,
    &.editSourceModal,
    &.regionSetupModal,
    &.createRegionalSourceModal,
    &.blogAdminFiltersModal,
    &.shopAdminFiltersModal,
    &.defaultModal,
    &.basketModal,
    &.sharingModal,
    &.checkoutModal,
    &.paymentsModal,
    &.weclappConnectionModal,
    &.pageModal,
    &.loomaModal,
    &.collectionPickerModal,
    &.configureCollectionModal,
    &.collectionModal,
    &.pickIconModal,
    &.loginModal,
    &.verifiedInfoModal {

      [role="dialog"] {
        --height: 90vh;
        height: 90vh;
        --min-height: 90vh;
        min-height: 90vh;
        --max-height: 90vh;
        max-height: 90vh;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        overflow: hidden;
      }

      &.cookiesModal,
      &.verifiedInfoModal {
        [role="dialog"],
        .ion-page {
          --background: var(--ion-color-primary);
          background: var(--ion-color-primary);
          --color: var(--ion-color-primary-contrast);
          color: var(--ion-color-primary-contrast);
        }
        .header-background {
          backdrop-filter: none !important;
        }
        ion-header,
        ion-content {
          ion-toolbar {
            --background: var(--ion-color-primary);
            background: var(--ion-color-primary);
            --color: var(--ion-color-primary-contrast);
          }
          --background: var(--ion-color-primary);
          background: var(--ion-color-primary);
          --color: var(--ion-color-primary-contrast);
          color: var(--ion-color-primary-contrast);
        }
      }
    }
  }
}

ion-app.loggedin {
  ion-modal {
    &.postPreviewModal,
    &.previewPostSocialSharingImagesModal,
    &.searchModal,
    &.createAdModal,
    &.createTargetGroupModal,
    &.connectBlogPage,
    &.connectShopPage,
    &.editShopSourceModal,
    &.editSourceModal,
    &.eventDetailModal,
    &.weclappConnectionModal,
    &.chooseActionSheetPage,
    &.defaultModal,
    &.pageModal,
    &.loomaModal,
    &.postModal,
    &.basketModal,
    &.paymentsModal,
    &.profileModal,
    &.editWebsiteModal,
    &.createWebsiteModal,
    &.postsAdminSettingsModal,
    &.writePostModal,
    &.collectionModal,
    &.storyModal,
    &.productModal {
      @media screen and (min-width: 1024px) {
        --max-width: calc(100vw - 270px);
        --width: calc(100vw - 270px);
        --height: 100%;
        width: calc(100vw - 270px);
        right: 0px;
        left: auto;

        .modal-wrapper {
          width: calc(100vw - 270px);
          height: 100vh;
          left: auto;
          right: 0px;
          bottom: 0px;
        }

        &.writePostModal {
          --max-width: 100vw;
          --width: 100vw;
          width: 100vw;

          .modal-wrapper {
            --max-width: 100vw;
            --width: 100vw;
            width: 100vw;
          }

          @media screen and (min-width: 1024px) {
            --max-width: calc(100vw - 270px);
            --width: calc(100vw - 270px);
            width: calc(100vw - 270px);

            .modal-wrapper {
              --width: calc(100vw - 270px);
              width: calc(100vw - 270px);
            }
          }
        }

        &.postPreviewModal,
        &.previewPostSocialSharingImagesModal {
          --width: 20vw;
          width: 20vw;
          height: 40vw;
          left: auto;
          right: 0px;
          top: 0px;
          bottom: 0px;
          margin-top: 7.5vw;
          margin-right: 5vw;
          background: url(./../../assets/img/iphone.webp) center center no-repeat;
          background-size: cover;
          padding: 17px 19px;

          .modal-wrapper {
            left: auto;
            right: 0px;
            height: 100%;
            border-radius: 20px;
          }

          @media only screen and (min-width: 1024px) and (max-height: 1366px) {
            background: var(--ion-background-color);
            --width: calc(100% - 270px);
            width: calc(100% - 270px);
            left: 0px;
            right: auto;
            bottom: auto;
            margin-top: auto;
            padding: 0px;
            height: 100%;

            .modal-wrapper {
              left: 0px;
              right: auto;
              border-radius: auto;
            }
          }
        }
      }
    }
  }
}
