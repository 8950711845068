ion-grid.storiesGrid {
    float: left;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: -15px;

    ion-row {
        padding-bottom: 0px;

        ion-col {
            flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 10)) * 100%) !important;
            --width: 25%;
            width: 25% !important;
            max-width: 25% !important;
            overflow: hidden;
            padding: 0px !important;

            ion-icon {
                font-size: 50px;
                margin: auto;
                display: block;
            }

            ion-avatar {
                --width: 50px;
                --height: 50px;
                margin: auto;
                display: block;
                margin-bottom: 3px;

                ion-img {
                    --background: #fff;
                    background: #fff;
                }
                
            }

            p,
            small,
            ion-label {
                float: left;
                width: 100%;
                text-align: center;
                margin-top: 5px;
                margin-bottom: 15px;
                font-size: 11px;
            }
        }
    }
}
