@import "~@ionic/angular/css/core.css";

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "intro.js/minified/introjs.min.css";
@import "intro.js/themes/introjs-dark.css";

@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';

@import '~@ionic/angular/css/ionic-swiper';

@font-face {
  font-family: "Now-Medium";
  src: url("./assets/fonts/Now/Now-Medium.otf");
}

#background-content {
  display: none !important;
}

pipeline-table-view {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

swiper.swiper {
  display: block !important;
  .swiper-slide {
      display: block !important;
  }
}

.forgotPassword {
  float: right;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 30px;
}

.version a {
  --color: var(--pipeline-sidebar-background-color-contrast) !important;
  color: var(--pipeline-sidebar-background-color-contrast) !important;
}

h1,
h2,
h3,
ion-card-title,
ion-card-subtitle {
  //font-family: "Now-Medium";
  font-weight: 600;
}

blockquote {
  width: 100%;
  margin: 0px;
}

ion-header {
  ion-buttons {
    .headerUserPic {
      margin-top: -1px;
      margin-right: -1px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      overflow: hidden;
      --background: #fff;
      background: #fff;

      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }

  ion-title {
    font-weight: 700;

    &[size="large"] {
      &.md {
        font-size: 26px;
      }
    }
  }
}

ion-card-content blockquote {
  margin-bottom: 15px;
}

pipeline-header-toolbar,
pipeline-header-suggestions-toolbar {
  &.top {
    display: none;
  }
}

$i: 2;
@while $i < 6 {
  $delay: $i * 15 - 30;
  @keyframes page-#{$i} {
    #{0 + $delay}% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    #{20 + $delay}% {
      opacity: 1;
    }
    #{35 + $delay}%,
    100% {
      opacity: 0;
    }
    #{50 + $delay}%,
    100% {
      transform: rotateY(0deg);
    }
  }
  $i: $i + 1;
}

ion-app,
ion-nav,
ion-tab,
ion-tabs,
.app-root,
.ion-page {
  &.introjs-fixParent {
    contain: layout size style !important;
  }
}

ion-content {

  > .container:not(ion-toolbar) {
    height: 100%;
  }

  .cdk-wrapper {
    position: relative;
    left: 0px;
    top: 0px;
    height: 100%;
    min-height: 80vh;
    width: 100%;
    max-width: 100vw;
  }
  
  #feedGrid,
  pipeline-post-grid,
  cdk-virtual-scroll-viewport {
    height: 100%;
    min-height: 82.5vh;
    width: 100%;
    max-width: 100vw;

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100% !important;
    }
    
  }

  pipeline-post-card {
    height: 500px;
  }

  ion-spinner {
    margin: 30vh auto;
    display: block;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

#appleid-signin {
  float: left;
  width: 100%;
  position: relative;
  display: grid;
  margin: auto;
  margin-top: 5px;
  text-align: center;
  left: 0px;
  right: 0px;

  > div {
      margin: auto;
      display: block;
      width: 50vw !important;
      max-width: 200px !important;
  }
}

@import "./src/theme/components/alert";
@import "./src/theme/components/badge";
@import "./src/theme/components/curve";
@import "./src/theme/components/introjs";
@import "./src/theme/components/iphone";
@import "./src/theme/components/grid";
@import "./src/theme/components/loader";
@import "./src/theme/components/menu";
@import "./src/theme/components/slides";
@import "./src/theme/components/profileFab";
@import "./src/theme/components/progress";

@media (prefers-color-scheme: dark) {
  .introjs-overlay {
    background: #000;
  }

  .ql-snow {
    .ql-picker {
      color: var(--ion-color-dark);
    }
    .ql-picker-options {
      background: var(--ion-background-color);
      border-color: transparent;
    }
    &.ql-toolbar {
      button {
        svg {
          filter: invert(100%);
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 100% !important;
    width: 1000px !important;
    margin: auto !important;
    left: 0px !important;
    right: 0px !important;
    display: block !important;
    float: none !important;
  }

  ion-menu {
    --max-width: 270px;
    --side-width: 270px;
  }

  ion-router-outlet {
    --side-max-width: 270px;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    max-width: 100% !important;
    width: 1440px !important;
  }
}

@media screen and (min-width: 2560px) {
  .container {
    max-width: 100% !important;
    width: 1920px !important;
  }
}

ion-button.subCategory {
  margin-right: 4px;
  zoom: 0.9;
  font-weight: 600;
  --font-size: 16px;
  font-size: 16px;
  --padding-top: 7px;
  --padding-bottom: 7px;
  --padding-start: 11px;
  --padding-end: 13px;

  ion-icon {
    margin-right: 3px;
  }
}

.before,
.after {
  content: "";
  position: fixed;
  left: 0px;
  width: 100vw;
  height: 15vh;
  z-index: 2;

  &.before {
    top: -1px;
    background: rgb(0, 0, 0) !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 75%
    ) !important;
  }

  &.after {
    background: rgb(0, 0, 0) !important;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ) !important;
    bottom: -1px;
    height: 20vh;
    opacity: 0.9;
  }
}

html.dark {

  .before {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 75%
      ) !important;
  }

  .after {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      ) !important;
  }
}

html.md {
  .before,
  .after {
    display: none;
  }
}

.scroll-row {
  flex-wrap: nowrap;
  overflow-x: scroll !important;
  overflow-y: hidden;
}

.emptyCard,
.noEntriesCard {
  text-align: center !important;
  ion-icon {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 40px;
  }
}

.left-align-buttons .action-sheet-button-inner {
  justify-content: flex-start;
  ion-icon {
    margin-right: 15px !important;
  }
}

.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media screen and (min-width: 769px) {
  .col-size-12 {
    max-width: 50% !important;
  }
}

@media screen and (min-width: 1025px) {
  .col-size-12:not(.peopleCol),
  .col-size-8:not(.peopleCol),
  .col-size-6:not(.peopleCol),
  .col-size-4:not(.peopleCol) {
    max-width: 33.333% !important;
  }
  .peopleCol {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.sponsoredPostItem {
  margin-top: -12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ion-label {
    margin-top: 5px;
    margin-bottom: 5px;
    small {
      float: left;
      margin-top: -3px;
      margin-bottom: 5px;
      font-size: 13px;
    }
  }
}

.videoMode .sponsoredPostItem {
  margin-top: 0px;
  margin-bottom: -20px;
}

ion-chip.sponsored {
  position: absolute;
  z-index: 1;
  margin: 5px;
  left: 0px;
  top: 0px;
  background: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-contrast);
  --font-size: 12px;
  font-size: 12px;
  --min-height: 12px;
  min-height: 12px;
  --font-weight: 600;
  font-weight: 500;
  --padding-top: 2px;
  --padding-bottom: 2px;
  height: auto;
}

.swiper-slide {
  overflow-y: scroll;
  display: block;
}

.paypal-checkout-sandbox {
  top: var(--ion-safe-area-top) !important;
}

#menuLogo {
  padding: 30px 0px;
  height: 112px;
  margin: auto;
  display: block;
  @media (prefers-color-scheme: dark) {
    filter: invert(100%);
  }
}

.plt-ios #menuLogo {
  height: 90px;
}

.descriptionText {
  --min-height: auto;
  margin-left: -15px;
}

.basketBtn {
  top: 6.5vh;
  z-index: 99 !important;
  ion-fab-button {
    position: relative !important;
    z-index: 99 !important;
  }
}

.footer-bar {
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;

  ion-segment.reactions {
    margin-left: -10px;
    margin-right: -10px;

    &.has-comments-btn {
      width: calc(100% - 40px) !important;
      float: left !important;
    }
  }

  ion-button.comment-btn {
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 0px;
    height: 28px;
  }
}

ion-loading {
  --spinner-color: var(--ion-color-dark-contrast);

  .loading-wrapper {
    --background: transparent !important;
    background: transparent !important;
    --box-shadow: none !important;
    box-shadow: none !important;
  }
}

ion-skeleton-text {
  &.image {
    width: 100%;
    height: 100%;
  }
}

ion-input.usernameInput {
  input {
    --padding-top: 0px;
  }
}

.loadingDetector {
  width: 0px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;

  video {
    width: 100vw;
    min-width: 100vw;
    max-height: 100vh;
    margin: auto;
    display: block;
    object-fit: cover !important;
    object-position: top center !important;

    @media screen and (min-width: 769px) {
      width: auto;
      min-width: auto;
    }

  }
}

.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.no-entries {
  opacity: 0.8;
  margin: 25vh auto;
  text-align: center;
}

@media screen and (min-width: 769px) {
  #postPage {
    max-width: 1010px;
    margin: auto;
    display: block;
    top: 25vh;
    position: absolute;
    left: 0px;
    right: 0px;
  }
}

@media screen and (min-width: 1024px) {
  .ion-focusable:hover {
    cursor: pointer !important;
  }
}

@media screen and (min-width: 1024px) {
  #postPage {
    top: 35vh;
    padding: 50px 7.5vw;
  }
}

.imgWrapper {
  height: 30vh;
  overflow: hidden;

  .headerImage {
    min-height: 100%;
    min-width: 100%;
    max-height: auto;
    max-width: auto;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

input[type="file"].file-upload {
  display: none;
}

.custom-file-upload {
  border: 1px solid var(--ion-color-dark);
  border-radius: 10px;
  padding: 4px 10px 1px 10px !important;
  display: inline-block;
  padding: 0px;
  cursor: pointer;
}

app-discover,
app-home,
app-local,
app-people,
app-profile {
  .introjs-tooltip {
    .introjs-tooltip-header {
      display: none;
    }
    .introjs-tooltiptext {
      padding: 0px !important;
    }
    .introjs-tooltipbuttons {
      border-top: 0px;
      padding: 0px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 5px;
    }
  }
}

ion-buttons {
  ion-button,
  ion-menu-button,
  ion-back-button {
    --color: var(--ion-background-white);
  }
}


calendar {

  .monthview-selected {
    background: var(--ion-color-primary) !important;
  }
  
  .monthview-eventdetail-timecolumn {
    min-width: 25vw !important;
    font-size: 14px !important;
  }

  .event-detail {
    text-overflow: ellipsis;
    /* max-width: 50vw; */
    white-space: nowrap;
    overflow: hidden;
  }
}

@import "./src/theme/components/button";
@import "./src/theme/components/card";
@import "./src/theme/components/editor";
@import "./src/theme/components/flag";
@import "./src/theme/components/headlineToolbar";
@import "./src/theme/components/modal";
@import "./src/theme/components/postPage";
@import "./src/theme/components/productDescription";
@import "./src/theme/components/segment";
@import "./src/theme/components/stage";

/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }
  .settingsFab {
    margin-top: 40px;
  }
  app-profile ion-content > ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .cover {
    top: 92px;
  }
  .settingsFab {
    margin-top: 40px;
  }
  app-profile ion-content > ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 Pro */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }
  .settingsFab {
    margin-top: 40px;
  }
  app-profile ion-content > ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 11 Pro Max */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .cover {
    top: 92px;
  }
  .settingsFab {
    margin-top: 40px;
  }
  app-profile ion-content > ion-fab[slot="fixed"][vertical="top"] {
    margin-top: 30px;
  }
}

/* iPhone 8 */

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 8 Plus */

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
}
