.profileFab {
  margin-top: -60px;
  margin-left: 20px;
  left: 0px;
  width: 100px;
  height: 100px;
  overflow: visible;
  border-radius: 10px;
  --border-radius: 10px;

  ion-fab-button {
    overflow: visible;
    width: 100px;
    height: 100px;
    --border-radius: 10px;
    border-radius: 10px;
    --background: #fff;
    background: #fff;

    img {
      border-radius: 10px;
      width: 100px;
      height: 100px;
    }

    ion-icon {
      position: absolute;
      right: 0px;
      top: 0px;
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      z-index: 3;
      border-radius: 30px;
    }
  }
}
