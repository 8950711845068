#storySlides {
  
  .swiper-pagination {
    position: fixed;
    top: calc(var(--ion-safe-area-top) + 80px);
    bottom: auto;
    z-index: 999;
  }

  .swiper-pagination-bullet {
    width: 75px;
    height: 5px;
    border-radius: 5px;
    z-index: 999;
    background: rgba(200, 200, 200, 0.9);
  }

  &.postsLengthXL {
    .swiper-pagination-bullet {
      width: 35px;
    }
  }

  &.postsLengthXXL {
    .swiper-pagination-bullet {
      width: 25px;
    }
  }

}
