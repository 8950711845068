.stage {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 99999999999 !important;

  @keyframes stageItemAnimation {
    from {
      opacity: 0;
      transform: translateY(100vh);
      zoom: 0;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      zoom: 2;
      opacity: 0.8;
    }
    75% {
      opacity: 0.7;
    }
    to {
      opacity: 0.5;
      transform: translateY(-100vh);
      zoom: 2.5;
    }
  }

  > span {
    position: fixed;
    top: 0px;
    left: 0px;
    font-size: 40px;
    transition: 1.5s all linear;
    opacity: 0;
    animation: stageItemAnimation 1.5s linear forwards;
    &:nth-child(1) {
      top: 15vw;
      left: 58vw;
      animation-delay: 0.15s;
    }
    &:nth-child(2) {
      top: 25vw;
      left: 68vw;
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      top: 35vw;
      left: 48vw;
      animation-delay: 0.35s;
    }
    &:nth-child(4) {
      top: 5vw;
      left: 28vw;
      animation-delay: 0.5s;
    }
    &:nth-child(5) {
      top: 75vw;
      left: 38vw;
      animation-delay: 0.6s;
    }
    &:nth-child(6) {
      top: 85vw;
      left: 78vw;
      animation-delay: 0.65s;
    }
    &:nth-child(7) {
      top: 95vw;
      left: 3vw;
      animation-delay: 0.2s;
    }
    &:nth-child(8) {
      top: 85vw;
      left: 38vw;
      animation-delay: 0.3s;
    }
    &:nth-child(9) {
      top: 05vw;
      left: 78vw;
      animation-delay: 0.6s;
    }
    &:nth-child(10) {
      top: 95vw;
      left: 18vw;
      animation-delay: 0.45s;
    }
  }
}
